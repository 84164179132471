html, body {
  margin: 0;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

body {
  font-family: var(--body-font-family);
  font-size: 1rem;
  color: var(--color-body-text-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-body-background-light);
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

section {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
}

section h2 {
  font-size: 1.1rem;
  margin: 1rem 0 0.2rem;
}

.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.CookieConsent {
  /* background: #2B373B; */
}

.CookieConsent a, .CookieConsent a:link, .CookieConsent a:visited,
.CookieConsent a:hover, .CookieConsent a:active {
  color: var(--color-body-text-dark);
}

.main-container {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  padding-top: calc(var(--header-height) + 0px);
}

.main-container section {
  padding-bottom: 1rem;
}

.main-container[data-has-sidebar] {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* width: 100%; */
}

.main-container[data-has-sidebar="true"] section {
  max-width: 728px;
}

.main-container[data-has-sidebar="false"] section {
  max-width: 1096px;
  border-right: 0;
}

.full-width {
  max-width: 1096px !important;
  border-right: 0;
}

.main-container > *:not(.home-sidebar) {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 1096px; */
  flex: 1 1 auto;
  padding-right: 20px;
  /* border-right: 1px solid var(--color-light-gray); */
  /* padding-top: 20px; */
}

.main-container[data-has-sidebar="false"] > *:not(.home-sidebar) {
  /* padding-right: 0; */
}

/* quero o primeiro h1 do main-container */
.main-container h1:nth-child(2) {
  margin-top: 0;
}

.main-container h1 {
  font-family: var(--header-font-family);
  font-size: 1.2rem;
}

.main-container a,
.main-container a:visited,
.mobile-menu a,
.mobile-menu a:visited {
  text-decoration: none;
  color: var(--color-blue-logo-old);
  font-weight: normal;
  font-family: var(--header-font-family);
}

.d-none {
  display: none !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-right,
.float-left {
  border-radius: 35px;
  margin: 20px;
  width: 300px;
}

#reenviar-codigo {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-start svg {
  margin-left: 10px;
}

.container {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* div que tem uma arrow para scroll to top */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border: 0px solid whitesmoke;
  border-radius: 50%;
  background: var(--color-blue-logo);
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-to-top__arrow-wrapper {
  animation: bounce 3s infinite;
}

.scroll-to-top__arrow {
  border: solid;
  border-color: whitesmoke;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
}

.horizontal-line {
  border-bottom: 1px solid var(--color-light-gray);
  margin: 0 0 1rem;
}

/* react-confirm-alert */
.react-confirm-alert-overlay {
  /* determina a cor de fundo do modal e transparencia */
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.react-confirm-alert {
}

.react-confirm-alert-body {
}

/* aumentando o seletor para ganhar relevancia */
div.react-confirm-alert div.react-confirm-alert-button-group {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

div.react-confirm-alert div.react-confirm-alert-button-group button {
  font-size: 0.8rem;
  font-weight: 400;
  /* color: var(--color-body-text-light); */
  color: whitesmoke;
  /* background-color: var(--color-blue-logo); */
  background-color: var(--color-dark-red);
  /* border: 1px solid var(--color-light-gray); */
  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  box-sizing: border-box;
}

div.react-confirm-alert div.react-confirm-alert-button-group button:last-child {
  /* color: var(--color-body-text-light); */
  /* background-color: white; */
  color: whitesmoke;
  background-color: var(--color-blue-logo);
  border: 1px solid var(--color-light-gray);
}

/* pre que contenha code */
pre > code {
  /* display: block; */
  /* background: rgb(248, 248, 248); */
  /* padding: 8px 12px; */
  /* border-radius: 4px; */
  /* border: 1px solid rgb(224, 224, 224); */
  /* font-size: 1rem; */
  /* line-height: 1.5; */
  /* font-family: 'Roboto Mono', monospace; */
  /* overflow-wrap: break-word; */
  /* text-rendering: optimizeLegibility; */
  white-space: pre-wrap !important;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.col {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0.5rem;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}

.col-3 {
  flex: 3;
}

.col-4 {
  flex: 4;
}

.col-5 {
  flex: 5;
}

.col-6 {
  flex: 6;
}

.anexo-info {
  margin-bottom: 1rem;
}

.link-button {
  background: none;
  border: none;
  color: var(--color-blue-logo);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}

.relative-container {
  position: relative;
}

.absolute-container {
  position: absolute;
}

svg.copy-icon {
  fill: whitesmoke;
  color: whitesmoke;
  font-size: 1.2rem;
  padding: 20px;
  cursor: pointer;
}

.copy-text {
  color: whitesmoke;
  font-size: 0.8rem;
}

/* titulo triângulo */
.titulo-triangulo {
  display: inline-block;
  background-color: var(--color-blue-logo);
  position: relative;
  /* Coloque o texto, fonte, cor, padding, etc. aqui */
  color: white;
  padding: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
}

.titulo-triangulo::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 0px;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-bottom: 2.5rem solid var(--color-blue-logo);
}

.error {
  color: darkred;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.success {
  color: darkgreen;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.search-result-item {
  display: block;
  flex-direction: column;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.search-result-item h2 {
  display: inline-block;
  cursor: pointer;
  font-size: 0.9rem;
  /* background: var(--color-blue-logo); */
  background: var(--color-light-gray);
  border-radius: 4px;
  /* color: white; */
  padding: 0.4rem;
  font-weight: 400;
}

.round-corner {
  border-radius: 10%;
}

.paypal-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.paypal-buttons-col {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.3rem;
  /* border: 1px solid var(--color-blue-logo); */
  border-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  padding: 0;
}

.paypal-buttons-col:hover {
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);
}

.paypal-buttons-title {
  display: block;
  width: 100%;
  padding: 10px 0;
  background: var(--color-blue-logo);
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  color: white;
}

.paypal-buttons-price {
  padding: 1.5rem;
  font-size: 1.8rem;
  font-weight: normal;
  color: var(--color-gray);
}

.paypal-buttons {
  display: block;
  width: 90%;
  margin: 0.5rem auto;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  html, body {
    font-size: 14px;
  }

  .paypal-buttons-container {
    flex-direction: column;
    display: flex;
    flex: 1;
  }

  .paypal-buttons-col {
    margin: 0.5rem 0;
    display: flex;
    flex: 1;
    width: 100%;
  }
}
