/*
  Cores do documento:

  #5172ff - azul da logo
  #F2E5E5 - rosa claro
  #C060A1 - rosa escuro
  #3B185F - roxo
  #ececf1 - cinza claro
*/

:root {
  /* --color-blue-logo: #5172ff; */
  --color-orange-light: rgba(255, 165, 0, 0.2);
  --color-orange-dark: rgba(255, 165, 0, 0.8);
  --color-orange: rgba(255, 165, 0, 0.5);
  --color-blue-footer: rgba(0, 0, 139, 0.8);
  --color-blue-logo-old: #3951b3;
  --color-blue-logo: #000;
  --color-light-blue-logo: #dbe8ff;
  --color-light-pink: #F2E5E5;
  --color-dark-pink: #C060A1;
  --color-purple: #3B185F;
  --color-light-gray: #ececf1;
  --color-body-text-light: #000;
  --color-body-text-dark: #ddd;
  --color-body-background-light: #fff;
  --color-body-background-dark: #000;
  --color-yellow: #f0e68c;
  --color-background-depoimento-light: var(--color-light-pink);
  --color-background-depoimento-dark: #111;
  --body-font-family: 'Open Sans', sans-serif;
  --header-font-family: 'Poppins', sans-serif;
  --color-white: #fff;
  /* cinza medio */
  --color-gray: #666;
  --color-dark-gray: #1c1d1f;
  --color-dark-red: #630606;
  --color-header-background: #3951b3;
  --header-height: 60px;
  /* paleta de cores para hashtags */
  --color-tag-1: #a1c0f5;
  --color-tag-2: #a1e7f5;
  --color-tag-3: #a1f5e1;
  --color-tag-4: #a1f5b7;
  --color-tag-5: #aaf59a;
  --color-tag-6: #c6f5a1;
  --color-tag-7: #e0f5a1;
  --color-tag-8: #f5e7a1;
  --color-tag-9: #f5cea1;
  --color-tag-10: #f5b2a1;
  --color-tag-11: #f5a1af;
  --color-tag-12: #f5a1ce;
  --color-tag-13: #e7a1f5;
  --color-tag-14: #c6a1f5;
  --color-tag-15: #afa1f5;
  --color-tag-16: #a1aaf5;
  --color-tag-17: #a1e7d3;
  --color-tag-18: #a1d3e7;
  --color-tag-19: #d3a1e7;
  --color-tag-20: #e7a1d3;
}
