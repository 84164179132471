.Toastify .Toastify__toast-body {
  text-align: center;
  font-size: 0.9rem;
  color: var(--color-gray);
  /* padding: 50px; */
}

.Toastify .Toastify__toast-theme--light {
   background-color: var(--color-light-gray);
   color: var(--color-body-text-light);
}

.Toastify .Toastify__close-button {
   color: var(--color-body-text-light);
}

/* toastify-icon-color-error */
.Toastify .Toastify__toast-body svg {
  fill: var(--color-blue-logo);
}

.Toastify .Toastify__toast--error .Toastify__toast-body svg {
  fill: red;
}

.Toastify .Toastify__toast--error {
   /* background-color: var(--color-light-pink); */
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 40% !important;
    transform: translateY(-40%) !important;
  }
}
