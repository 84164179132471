.home-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 728px;
  padding-right: 50px;
  border-right: 1px solid var(--color-light-gray);
  padding-top: 20px;
}

.home-post-item {
  display: block;
}

.home-post-item hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid var(--color-light-gray);
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-post-item-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* border: 1px solid blue; */
}

.home-post-item-body:hover {
  /* background-color: var(--color-light-gray); */
}

.home-post-item-titulo {
  padding-bottom: 8px;
  cursor: pointer;
}

.home-post-item-titulo h1,
.home-post-item-titulo a {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #333 !important;
}

.home-post-item-autor {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  color: var(--color-gray);
}

.home-post-item-foto {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.home-post-item-foto,
.home-post-item-autor {
  cursor: pointer;
}

.home-post-item-texto {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.home-post-item-texto p {
}

.home-post-item-capa {
  display: flex;
  padding: 20px;
  text-align: center;
  justify-content: center;
}

.home-post-item-capa img {
  width: 140px;
  height: 140px;
  border-radius: 6px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); */
  cursor: pointer;
}

.tabela-planos {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.tabela-planos th, .tabela-planos td {
    border: 0px solid #ddd;
    padding: 10px;
    text-align: center;
}

.tabela-planos th {
    background-color: var(--color-blue-footer);
    color: whitesmoke;
}

.tabela-planos tr:nth-child(even) {
    background-color: var(--color-orange-light);
}

.tabela-planos tr:hover {
    background-color: #f1f1f1;
}

.no-condominio {
  display: flex;
  flex-direction: row;
  background-color: var(--color-orange-light);
  padding: 1rem;
  border-radius: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 1rem;
}

.no-condominio svg {
  /* font-size: 3rem; */
  width: 30px;
  height: 30px;
  fill: var(--color-gray);
  /* padding: 0.5rem; */
}

.no-condominio div {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: auto 0;
}

.planos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* height: 100%; */
  margin: 0 auto 2rem auto;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  color: #333;
  text-align: center;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  gap: 1rem;
  flex-wrap: wrap;
}

.plano {
  flex-basis: calc(33.333% - 1rem);
  width: 100%;
  /* height: 100%; */
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  /* cursor: pointer; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid #ccc;
  /* border-top: 5px solid #ccc; */
  /* border-bottom: 5px solid #ccc; */
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  background-color: #fff;
}

.plano p {
  width: 80%;
  margin: 1rem auto;
  text-align: center;
}

.plano-features p {
  display: flex;
  align-items: center;
}

.plano-features svg {
  margin-right: 0.5rem;
}

.plano svg {
  fill: rgba(0, 128, 0, 1);
}

.plano h2 {
  font-size: 1.5rem;
  font-weight: 300;
  color: whitesmoke;
  margin: 0;
  padding: 0;
  background-color: rgba(205, 127, 50, 1); /* bronze */
  border-bottom: 1px solid #ccc;
  padding: 1rem;
}

.plano-preco-cortado {
  color: #555;
  text-decoration: line-through;
}

.plano-preco-final {
  font-weight: 600;
  font-size: 2rem;
  /* nao deixe a palavra cortar */
  white-space: nowrap;
}

.planos > .plano > button.plano-button {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

.plano-divider {
  margin: 0 auto;
  width: 80%;
  height: 1px;
  background-color: #ccc;
}

.plano-bronze {
  /* border: 1px solid rgba(205, 127, 50, 1); [> bronze <] */
  border: 1px solid #ccc;
}

.plano-bronze h2 {
  background-color: rgba(205, 127, 50, 1); /* bronze */
}


.plano-prata {
  /* border: 1px solid var(--color-blue-footer); */
  border: 1px solid #ccc;
}

.plano-prata h2 {
  background-color: var(--color-blue-footer);
}

.plano-ouro {
  /* border: 1px solid rgba(0, 128, 0, 1); [> ouro <] */
  border: 1px solid #ccc;
}

.plano-ouro h2 {
  background-color: rgba(0, 128, 0, 1); /* ouro */
}

.plano-diamante {
  /* border: 1px solid rgba(128, 0, 128, 1); [> diamante <] */
  border: 1px solid #ccc;
}

.plano-diamante h2 {
  /* give some purple */
  background-color: rgba(128, 0, 128, 1); /* diamante */
}

.plano-vitalicio h2 {
  background-color: rgba(125, 0, 30, 1); /* dark red */
}

#adesao h2 {
  margin-bottom: 1rem;
}

#sobre img {
  max-width: 70%;
  margin: 0 auto;
}

.adesao-list li {
  margin-bottom: 0.5rem;
}

.info-p {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(255, 165, 0, 0.2);
}

.info-p svg {
  width: 2rem;
  height: 2rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.garagem-botao-aderir {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 300px;
  justify-content: center;
  font-size: 1.2rem;
  background-color: rgba(0, 128, 0, 1);
  border-radius: 5px;
  color: #fff !important;
  padding: 0.5rem;
  margin-bottom: 1rem;
  align-self: center;
}

.green-info {
  font-size: 0.8rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: rgba(0, 128, 0, 1);
}

.login-test {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgba(255, 165, 0, 0.2);
  border-radius: 10px;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  .home-post-item-capa img {
    width: 80px;
    height: 80px;
  }

  .home-post-item-texto {
    /* font-size: 0.8rem; */
  }

  .home-post-item-titulo h1 {
    /* font-size: 1rem; */
  }

  .planos {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }

  .plano {
    flex-basis: 100%;
  }

}

