#footer {
  /* colocar fixo no final da página */
  display: flex;
  align-self: flex-end;
  width: 100%;
  /* max-height: 460px; */
  background-color: var(--color-blue-footer);
  color: whitesmoke;
  padding: 3rem;
  font-family: var(--header-font-family);
  margin: 0;
  margin-top: 1.5rem !important;
  align-items: center;
}

#footer .row {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  align-items: flex-start;
}

#footer .row .col {
  padding: 0.5rem;
  width: 100%;
  align-items: flex-start;
  align-self: flex-start;
}

#footer .row .col:last-child {
  flex: 0 0 100%;
}

#footer .row .col h1 {
  text-transform: uppercase;
  font-size: 1rem;
}

#footer a,
#footer a:visited,
#footer .row .col p {
  /* color: var(--color-body-text-light); */
  color: whitesmoke;
  text-decoration: none;
}

/* footer a hover vai ter um box-shadow */
#footer a:hover {
 text-shadow: 0 0 2px rgba(0,0,0,0.3);
}

.dark #footer a:hover {
  text-shadow: 0 0 2px rgba(255,255,255,0.8);
}

#footer .nav-item {
  text-transform: uppercase;
}

#copyright {
}

#footer .col p {
  display: flex;
  align-items: center;
}

#footer .col p svg {
  margin-right: 0.5rem;
}

a.footer-hot-link,
a.footer-hot-link:visited {
  background-color: #ccc;
  text-decoration: none;
  color: var(--color-blue-footer) !important;
  padding: 0px 8px;
  border-radius: 5px;
  font-size: 0.9rem;
}

