.brinquedo-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 0.9rem;
}

.brinquedo-container h1 {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
}

.brinquedo-container h1 svg {
  cursor: pointer;
  color: var(--color-gray);
}

.brinquedos {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0rem 0;
  padding: 1rem;
  /* padding: 2rem; */
  /* max-width: calc((7rem + 1.2rem) * 4); [> N cards por linha <] */
}

.brinquedo-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin: 0.6rem;
  background-color: white;
  border-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  color: var(--color-body-text-light);
  padding: 0.5rem;
  cursor: pointer;
}

.brinquedo-card:hover {
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);
}

.brinquedo-card svg {
  font-size: 5rem;
  fill: var(--color-blue-logo);
}

.brinquedo-card-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}


.brinquedo-card-info span {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  color: var(--color-gray);
}

.brinquedo-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.brinquedo-label {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--color-light-gray);
  margin: 0;
  border: 1px solid var(--color-light-gray);
  min-width: 30%;
  max-width: 30%;
}

.brinquedo-field {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 0;
}

.brinquedo-label svg {
  cursor: pointer;
  color: #999;
}

.brinquedo-field svg {
  cursor: pointer;
  font-size: 1rem;
  color: whitesmoke;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  /* border: 1px solid var(--color-light-gray); */
  background-color: var(--color-blue-logo);
  border-radius: 5px;
  padding: 5px;
}

.brinquedo-field span + svg {
  margin-left: 0.5rem;
}

.brinquedo-field-icons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: 0.5rem;
}

.brinquedo-input,
.brinquedo-field input {
  border: 1px solid var(--color-light-gray);
  background-color: transparent;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  padding: 0.3rem;
  color: var(--color-body-text-light);
  width: 80%;
}

.brinquedo-input {
  width: 100%;
}

.brinquedo-field input:focus {
  outline: none;
  border: 1px solid var(--color-blue-logo);
}

.brinquedo-icons-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  padding: 0 20px;
}

.brinquedo-icons-list svg {
  color: #ccc;
  cursor: pointer;
  font-size: 1.5rem;
}

.brinquedo-icons-list svg.active {
  color: var(--color-blue-logo);
}

.personalidade-textarea {
  overflow-y: hidden;
  resize: none;
  min-height: 50px;
}

.brinquedo-modos-list {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px;
}

.brinquedo-modo-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  background-color: var(--color-light-gray);
  border-radius: 10px;
  padding: 5px;
  width: 120px;
  height: 30px;
  flex-direction: row;
  font-size: 0.8rem;
  cursor: pointer;
}

.brinquedo-modo-card-active {
  background-color: var(--color-blue-logo);
  color: white;
  box-shadow: 0px 2px 8px 0px rgba(0,0,55,0.5);
}

.chart-select-date {
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem 0;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.chart-select-date select {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  font-size: 0.8rem;
  font-family: "Open Sans", sans-serif;
  color: var(--color-body-text-light);
}

.chart-select-date select + select {
  margin-right: 0.5rem;
}

.chart-select-date svg {
  cursor: pointer;
}

/* quando passa o mouse sobre uma barra do gráfico */
path.recharts-tooltip-cursor {
  fill: var(--color-light-gray);
}

.chart-progress-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin: 1rem 0;
  gap: 0.5rem;
}

.chart-progress {
  width: 100%;
  height: 20px;
  background-color: var(--color-light-gray);
  border-radius: 10px;
  /* margin-top: 20px; */
  overflow: hidden;
}

.chart-progress-bar {
  height: 100%;
  background-color: var(--color-blue-logo);
  transition: width 0.3s ease-in-out;
}

.chart-progress-bar-warning {
  background-color: darkorange;
}

.chart-progress-bar-error {
  background-color: darkred;
}

.chart-progress-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  color: white;
  /* font-weight: bold; */
  text-align: center;
  width: 100%;
  height: 100%;
  /* me dê um darkred um pouco mais suave */
  background-color: rgba(139, 0, 0, 0.8);
  border-radius: 10px;
  overflow: hidden;
}

.chart-progress-info {
  /* label da barra de progresso */
  /* nowrap para não quebrar a linha */
  white-space: nowrap;
  font-size: 0.8rem;
}

.brinquedo-info {
  background: var(--color-light-blue-logo);
  border-radius: 10px;
  padding: 20px;
  font-size: 0.9rem;
  text-align: center;
  margin: 0 0 1rem 0;
}

.brinquedo-observacoes {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: var(--color-light-blue-logo);
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.brinquedo-observacoes svg {
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 1rem;
  color: var(--color-gray);
}

.brinquedo-preco {
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.brinquedo-preco-body {
  align-items: center;
  /* border: 1px solid var(--color-light-gray); */
  margin: 0;
  padding: 1rem;
}

.brinquedo-preco h2 {
  display: block;
  /* background: var(--color-blue-logo); */
  background: black;
  color: whitesmoke;
  /* border-radius: 10px 10px 0 0; */
  border-radius: 0;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.brinquedo-preco img {
  display: block;
  margin: 0 auto;
  max-height: 300px;
}

.brinquedo-preco span {
  display: block;
  color: var(--color-gray);
  text-align: center;
  margin-top: 0.5rem;
}

.brinquedo-preco p.preco {
  background: var(--color-light-gray);
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
}

.brinquedo-preco p.preco:hover {
  /* cursor: pointer; */
  /* box-shadow: 0px 2px 4px 0px rgba(0,0,55,0.3); */
}

.brinquedo-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid var(--color-light-gray);
  background: white;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  .brinquedos {
    justify-content: center;
  }

  .brinquedo-field input {
    width: 100%;
  }

  .brinquedo-preco {
    flex-direction: column;
  }
}
