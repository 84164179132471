.breadcrumb-container {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  align-items: center;
  margin-bottom: 0.6rem;
}

.breadcrumb-list {
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  display: inline-block;
  padding: 8px;
  margin-right: 5px;
  /* background: var(--color-light-gray); */
  background: var(--color-blue-footer);
  transform: skew(-20deg);
  cursor: pointer;
  opacity: 1;
}

.breadcrumb-item-last {
  background: none;
  cursor: default;
}

li.breadcrumb-item-last h1 {
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--color-gray);
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item a,
.breadcrumb-item a:visited,
.breadcrumb-item h1 {
  display: block;
  transform: skew(20deg);
  font-size: 0.8rem;
  font-weight: 400;
  /* color: #666; */
  color: whitesmoke;
}

.breadcrumb-header {
  justify-content: flex-start;
}

.breadcrumb-header h1 {
  margin-right: 10px;
  margin-left: 10px;
}

.breadcrumb-header h1 {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}


