.markdown-container {
  display: flex;
  flex-direction: column;
}

.post-image {
  max-width: 400px !important;
}

.markdown-container blockquote {
  display: flex;
  margin: 0 0 1rem;
  padding: 0.5rem;
  /* border-left: 3px solid var(--color-blue-logo); */
  border-left: 3px solid rgba(0, 0, 0, 0.2);
  /* color: var(--color-gray); */
  color: #262626;
  background-color: rgba(245, 231, 161, 0.2);
}

.markdown-container p {
  margin: 1rem 0 !important;
}

.__react_modal_image__header {
  background-color: var(--color-blue-logo) !important;
  opacity: 0.9 !important;
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  .post-image {
    max-width: 90% !important;
  }
}
