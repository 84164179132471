section#inbox-section {
  padding: 0;
  max-width: 748px;
}

.inbox-header {
  height: 30px;
  margin: 0 0 20px;
  padding: 0;
  background: var(--color-light-gray);
  /* border-radius: 10px; */
  /* color: whitesmoke; */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
}

.inbox-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.inbox-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;
  border-right: 1px solid var(--color-light-gray);
  /* criar scroll */
  overflow-y: auto;
  min-width: 220px;
  max-width: 220px;
}

.inbox-center p {
  font-size: 0.8rem;
}

.inbox-right {
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 0;
  overflow-y: auto;
}

.inbox-center {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 30%;
  /* background-color: var(--color-light-gray); */
}

.inbox-center svg {
  font-size: 3rem;
  color: var(--color-blue-logo);
}

.inbox-contact {
  display: flex;
  flex-direction: row;
  flex: 1;
  cursor: pointer;
}

.inbox-contact-selected {
  background-color: var(--color-light-gray);
}

.inbox-contact-foto {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.inbox-contact-foto img {
  width: 35px;
  height: 35px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.inbox-contact-status {
  display: flex;
  flex: 1;
  padding: 0;
  flex-direction: column;
  /* background-color: var(--color-light-gray); */
}

.inbox-contact-status p {
  margin: 0;
  padding: 0.2rem;
}

.inbox-contact-nome {
  font-size: 0.9rem;
}

.inbox-contact-online {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 0.7rem;
  color: var(--color-gray);
}

.inbox-contact-unread {
  font-weight: 600;
}

.inbox-contact-unread-count {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.inbox-contact-unread-count span {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

span.online svg {
  display: inline-flex;
  flex: 1;
  font-size: 0.8rem;
  align-items: center;
  color: green;
}

span.offline svg {
  display: none;
}

.conversa-container {
  position: relative;
  height: 550px;
  overflow-y: auto; 
  padding: 1rem;
}

.conversa-header {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  margin: 0;
  padding: 0 0.5rem;
  border-bottom: 1px solid #ddd;
  /* background-color: var(--color-light-gray); */
  display: flex;
  /* flex: 1; */
  height: 50px;
  align-items: center;
}

.message-input-container {
  padding: 0 0.5rem;
  margin: 0;
  min-height: 60px;
  background-color: #fff; 
  display: flex;
  background-color: var(--color-light-gray);
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
}

.message-input {
  padding: 0.5rem;
  flex: 1;
  margin-right: 1em;
  border: 1px solid #ddd;
  border-radius: 15px;
  color: #333;
  resize: none; 
  overflow: hidden; /* Esconde a barra de rolagem */
  /* height: 24px !important; [> Altura inicial do textarea <] */
}


.edit-message-input,
.edit-message-input:focus {
  outline: none;
  margin: 0 0.5rem;
  padding: 0.5rem;
}

.message-input:focus {
  outline: none;
  border-color: #bbb; /* opcional: mude a cor da borda ao focar */
}

.message-item p {
  display: inline-block;
  font-size: 0.9rem;
  background: var(--color-light-gray);
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem 0 0;
}

.message-item-right {
  display: flex;
  flex-direction: row-reverse;
}

.message-item-right p {
  background: var(--color-blue-logo);
  color: #fff;
  cursor: pointer;
}

.message-actions {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin-right: 0.5rem;
}

.message-item svg,
.message-actions svg {
  font-size: 0.9rem;
  color: var(--color-gray);
  cursor: pointer;
  margin-right: 0.5rem;
}

.inbox-contact-back {
  display: flex;
  width: 2.5rem;
  cursor: pointer;
}

.inbox-contact-back svg {
  font-size: 1.5rem;
  color: var(--color-blue-logo);
}

@media (min-height: 600px) {
    .conversa-container {
        height: 400;
    }
}

@media (min-height: 800px) {
    .conversa-container {
        height: 600px;
    }
}

@media (min-height: 1000px) {
    .conversa-container {
        height: 800px;
    }
}

@media (min-height: 1200px) {
    .conversa-container {
        height: 1000px;
    }
}

/* mobile query */
@media (max-width: 600px) {
  .inbox-left {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .inbox-container {
    position: relative;
    display: flex;
    flex: 1;
  }

  .inbox-right {
    /* position: absolute; */
    top: 0;
    left: 0;
    /* right: 0; */
    /* bottom: 0; */
    width: 100%;
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
  }

  .conversa-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  .message-input-container {
    background: white;
    margin-bottom: 2rem;
  }

  .inbox-center {
    display: flex;
    flex: 1;
    padding: 1rem 2rem;
    justify-content: center;
  }

  .inbox-center p {
    font-size: 0.9rem;
    text-align: center;
  }
}
