/* componente Anexos */
.anexos-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* border: 1px solid var(--color-light-gray); */
  font-size: 0.8rem;
}

.anexos-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.anexos-container > .anexos-row:first-child {
  background-color: var(--color-light-gray);
  font-weight: 500;
  font-size: 0.9rem;
  /* text-transform: uppercase; */
}

.anexos-col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  min-width: 6rem;
}

.anexos-col:first-child {
  flex-grow: 3;
}

.anexos-col:last-child {
  align-items: center;
}

.anexos-col-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.anexos-col-image span {
  cursor: pointer;
  color: var(--color-blue-logo);
  word-break: break-word;
}

/* .anexos-col-image img:not(.__react_modal_image__medium_img) { */
.anexos-image {
  max-width: 50px !important;
  margin-right: 0.5rem;
}

.anexos-expand-icon {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
}

.anexos-header {
  background-color: var(--color-blue-logo);
  color: white;
  min-height: 1.5rem;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.anexos-header svg {
  color: white;
  fill: white;
}

.anexos-action-icon {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--color-gray);
}

.anexos-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 1000px; 
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; 
}

.anexos-content.collapsed {
  max-height: 0;
}

.widget-thumb {
  width: 3rem;
  height: 2rem;
  margin-right: 0.5rem;
  color: var(--color-gray);
}

@media (max-width: 768px),
(orientation: landscape) and (max-height: 600px) {
  .anexos-container {
    max-width: 100%;
  }

  .anexos-container .home-post-item-capa img {
    width: 120px;
    height: 120px;
  }
}
